/* variables */
$text-color: #333;
$bg-color: #f1f1f1;
$light-color: #fff;
$dark-color: #000;

$ff-primary: 'Poppins', sans-serif;

html,
body,
#root {
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
  height: 100%;
}

.red {
  color: red;
}

.home {
  overflow: auto;
  height:100%;
  min-height: 100%;
  background-color: $text-color;
  background-repeat: no-repeat;

  h1 {
    padding-top: 50px;
    text-align: center;
    color: $light-color;
  }

  div {
    text-align: center;
  }

  span {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $light-color;
  }

  .setting-container {
    margin-bottom: 5px;
  }

  &__card-container {
    color: $text-color
  }

  &__white_div {
    text-align: center;
    color: $light-color;
  }
}

.overflow-protection {
  padding-bottom: 200px;
}

.padded-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
}

.qr {
  h2 {
    text-align: center;
  }

  img {
    border-radius: 10%;
  }

  &__preview {
    max-width: 80vw;
    max-height: 80vh;
  }

  div {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}


@keyframes cardflip {
  from {
    transform: rotateY(180deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

@keyframes cardappear {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes cardTitleAndActionsAppear {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dialog {
  height: 200px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid grey;
  animation: cardflip 4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_title {
    animation: cardTitleAndActionsAppear 5s ease;
  }

  &_content {
    animation: cardappear 4s;
  }

  &_actions {
    animation: cardTitleAndActionsAppear 5s ease;
  }
}

#card_text {
  color: $text-color;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#leaderboard_text {
  color: $text-color
}

#factButton:disabled {
  color: white;
  background-color: rgb(139, 139, 139);
}